define("js2-admin/pods/components/matter/subjects/tags-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+es21Dlo",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[12,\"id\",[29,\"concat\",[\"tags-\",[25,[\"row\",\"id\"]]],null]],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"list-unstyled d-flex flex-wrap\"],[11,\"style\",\"gap: 5px;\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"row\",\"tags\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[9],[0,\"\\n                \"],[7,\"span\"],[12,\"style\",[30,[\"line-height: 1.5rem; \",[29,\"if\",[[24,1,[\"color\"]],[29,\"concat\",[\"background-color: \",[24,1,[\"color\"]],\" !important;\"],null]],null]]]],[12,\"class\",[30,[\"font-weight-normal mt-1 text-wrap text-white rounded p-1\\n                        \",[29,\"if\",[[29,\"eq\",[[24,1,[\"public\"]],1],null],\"bg-success border-success\",\"bg-info border-info\"],null]]]],[12,\"title\",[24,1,[\"name\"]]],[9],[0,\"\\n                    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/tags-cell/template.hbs"
    }
  });

  _exports.default = _default;
});